/**
 * Parses a text string containing featured card data and converts it into a structured array.
 * @param {string} featuredFetchText - The raw text data fetched for featured cards.
 * @returns {Array} An array of objects, each representing a featured card with its details.
 */
export function prepareFeaturedCard(featuredFetchText, theme) {
    // Split the input text by newline to process each category of data separately
    const jsonSplit = featuredFetchText.split('\n');
    // Initialize an empty array to hold the structured featured card data
    const featuredData = [];
    // Split each category of data by tab to separate individual entries
    const img = jsonSplit[0].split('\t'); // First line contains image URLs
    const urls = jsonSplit[1].split('\t'); // Second line contains links associated with each image
    const title = jsonSplit[2].split('\t'); // Third line contains titles for each card
    const linkType = jsonSplit[3].split('\t'); // Fourth line contains the type of link (e.g., internal, external)
    const lightColor = jsonSplit[4].split('\t'); // Fifth line contains color codes for each card
    const darkColor = jsonSplit[5].split('\t'); // Fifth line contains color codes for each card

    // Iterate over the length of any data array (assuming all are of equal length)
    for (let i = 0; i < img.length; i++) {
        if (i == 0) continue; // Skip excel header
        // Construct an object for each featured card and push it into the featuredData array
        featuredData.push({
            thumbnail: img[i], // Image URL
            links: urls[i], // Associated link
            title: title[i], // Card title
            linkType: linkType[i], // Type of link
            color: theme == 'light' ? lightColor[i] : darkColor[i] // lightColor code
        });
    }

    // Return the structured array of featured card data
    return featuredData;
}

export function prepareFeaturedTag(homepageReviewData, theme) {
    // Split the input text by newline to process each category of data separately
    const jsonSplit = homepageReviewData.split('\r');
    // Initialize an empty array to hold the structured featured card data
    const tagData = { title: {}, links: [] };
    // Iterate over the length of any data array (assuming all are of equal length)
    for (let i = 0; i < jsonSplit.length; i++) {
        const item = jsonSplit[i].trim().split('\t');
        if (i == 0) {
            tagData.title = item[0];
            continue;
        }
        tagData.links.push({
            name: item[0], // Image URL
            link: item[1], // Associated link
            color: theme == 'light' ? item[2] : item[3] // Associated link
        });
    }
    // Return the structured array of featured card data
    return tagData;
}
