/**
 * The Chevron Down icon
 * @returns SVG
 */

type Props = { color: string; height?: number; width?: number };
export default function ChevronDown({ color, height, width }: Props): JSX.Element {
    const isHeight = height ? height : 9;
    const isWidth = width ? width : 10;
    return (
        <>
            <svg width={isWidth} height={isHeight} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.75 1.125L4.5 4.875L8.25 1.125"
                    stroke={color}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
