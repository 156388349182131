import styles from '@styles/common-scss/_introTextFull.module.scss';
import { useRouter } from 'next/router';

interface Props {
    introTitle?: string;
    introContent: string;
}

export default function IntroTextFull({ introContent, introTitle = '' }: Props): JSX.Element {
    const router = useRouter();
    return (
        <>
            {introContent && !router.query.page && (
                <div className={styles.introSection} id="intro-content">
                    {introTitle && <h1 className={styles.introTitle}>{introTitle}</h1>}
                    {/* if youre on a paginated page,say page=2, then dont show the Intro Box */}
                    <section className={styles.introContainer}>
                        <div
                            id="basic-content-box"
                            className={styles.introContent}
                            dangerouslySetInnerHTML={{ __html: introContent }}
                        />
                    </section>
                </div>
            )}
        </>
    );
}
